import { UkeyMiddleware, PARAM } from '@/utils/ukeyMiddleware'
import { MessageBox } from 'element-ui'
// import request from '@/utils/request'
function getErr (err) {
  return err ? { message: err } : {}
}
export class Ukey {
  // eslint-disable-next-line space-before-function-paren
  constructor (self) {
    this.self = self
    this.certInfo = {}
    this.keyNews = { certSn: '' }
    this.applicationName = 'wellhope'
    this.containerName = 'LAB_USERCERT'
    this.ukeyMiddleware = new UkeyMiddleware('admin')
  }
  connect () {
    return this.ukeyMiddleware.connect().then(res => {
      if (res.code !== 0) {
        const h = this.self.$createElement
        MessageBox.alert(
          h('p', undefined, [
            h('span', undefined, '您还未安装豸信CA数字证书助手，请'),
            h(
              'a',
              {
                on: {
                  click: () => {
                    this.self.$util.downloadById(this.self.CONFIG.APPID)
                  }
                }
              },
              '【下载安装】'
            ),
            h('span', undefined, '后进行变更')
          ]),
          '提示',
          {
            type: 'warning',
            confirmButtonText: '好的'
          }
        )
        throw getErr(false)
      } else {
        return this.ukeyMiddleware.handle('GetUkeyList', {})
      }
    }).then(res => {
      if (res.code === 0 && res.data.length > 0) {
        // return Promise.resolve(res.data)
        this.ukeyName = res.data[0]['uKey-alias']
        return this.disconnectUkey()
      } else {
        MessageBox.alert(
          '请插入Ukey。',
          '提示',
          {
            type: 'warning',
            confirmButtonText: '好的'
          }
        )
        throw getErr(false)
      }
    }).then(() => {
      return this.ukeyMiddleware.handle('ConnectUkey', { 'uKey-alias': this.ukeyName, applicationName: this.applicationName, containerName: this.containerName })
    }).then(res => {
      if (res.code !== 0) { throw getErr('Ukey链接失败') }
      this.devHandle = res.data.devHandle
      return this.ukeyMiddleware.handle('ExportMyUserCert', { devHandle: this.devHandle, CertType: 1 })
    }).then(res => {
      if (res.code !== 0) { throw getErr('读取证书信息失败') }
      let base64Cert = res.data.certContent
      this.base64Cert = base64Cert
      return this.ukeyMiddleware.handle('GetCertSpecificInfo', { base64Cert, infoType: PARAM.SGD_CERT_SERIAL })
    }).then(res => {
      if (res.code !== 0) { throw getErr('读取证书信息失败') }
      this.certSn = res.data.certInfo.toLowerCase()
      return Promise.resolve(this.certSn)
    })
  }
  ukeyLogin (userPin) {
    userPin = userPin + ''

    return this.ukeyMiddleware.handle('UkeyLogin', { devHandle: this.devHandle, userPin, appName: '' }).then(res => {
      if (res.code !== 0) {
        if (res.code === 0x0A000024) {
          throw getErr('口令错误，请重新输入,剩余可验证次数' + res.data.retryCount + '次')
        } else {
          throw getErr(res.msg || '登录失败，请稍候再试')
        }
      }

      return Promise.resolve(res)
    })
  }
  writeCert ({ signCertBase64, encCertBase64, enCertPrivateKey, sealDTO }, type) {
    return this.ukeyMiddleware.handle('ImportEncPrivKeyAndCerts', {
      devHandle: this.devHandle,
      symAlgId: PARAM.SGD_SM1_ECB,
      base64SignCert: signCertBase64,
      base64EncCert: encCertBase64,
      base64WrappedEncKey: enCertPrivateKey,
      applicationName: '',
      containerName: ''
    }).then(res => {
      if (type && res.code === 0 && sealDTO && sealDTO.length >= 1) {
        return this.ukeyMiddleware.handle('PrivOperlnWriteSeal', { devHandle: this.devHandle })
      } else if (!type && res.code === 0 || !sealDTO && type && res.code === 0) {
        return res
      } else {
        throw getErr('印章格式化失败')
      }
    }).then(res => {
      if (res.code === 0) {
        if (sealDTO && sealDTO.length === 1) {
          return this.ukeyMiddleware.handle('WriteSealToUkey', { devHandle: this.devHandle, sealBase64Content: sealDTO[0].sealBase64 })
        } else if (sealDTO && sealDTO.length > 1) {
          return this.circularRequest(sealDTO)
        } else {
          return res
        }
      } else {
        throw { message: '证书写入失败', code: -1 }
      }
    }).then(res => {
      if (res.code === 0) {
        // this.disconnectUkey()
        return Promise.resolve(true)
      } else {
        throw { message: '印章写入失败', code: -1 }
      }

    }).catch((err) => {
      console.log(err);
      throw err
    });
  }
  checkUkey () {
    return this.ukeyMiddleware.handle('ExportMyUserCert', { devHandle: this.devHandle, CertType: 1 }).then(res => {
      if (res.code !== 0) { throw { message: '读取证书信息失败', code: -1 } }
      let base64Cert = res.data.certContent
      this.base64Cert = base64Cert
      return this.ukeyMiddleware.handle('GetCertSpecificInfo', { base64Cert, infoType: PARAM.SGD_CERT_SERIAL })
    }).then(res => {
      if (res.code !== 0) { throw { message: '读取证书信息失败', code: -1 } }
      this.keyNews['certSn'] = res.data.certInfo.toLowerCase()
      return this.keyNews
    })
  }
  async readUkeySeal (sealNum) {
    this.keyNews['esID'] = []
    for (let i = 0; i < sealNum; i++) {
      await this.ukeyMiddleware.handle('ReadUkeySealAndParse', { devHandle: this.devHandle, sealIndex: i + 1 }).then(res => {
        if (res.code !== 0) {
          throw { message: '读取ukey内印章信息失败', code: -1 }
        }
        this.keyNews['esID'].push(res.data.esID)
      })
    }
    console.log(this.keyNews)
    return this.checkUkey()
    // this.disconnectUkey()

  }
  async circularRequest (itemList) {
    let news
    for (let i = 0; i < itemList.length; i++) {
      await this.ukeyMiddleware.handle('WriteSealToUkeyEx', {
        devHandle: this.devHandle, sealBase64Content: itemList[i].sealBase64, sealIndex:
          i + 1
      }).then(res => {
        console.log(res, i, itemList.length - 1)
        if (res.code !== 0) {
          throw { message: '读取证书信息失败', code: -1 }
        } else if (i === itemList.length - 1) {
          news = res
        }
      })
    }
    return news
  }
  // dn, asymIdStr
  getPkcs10 (dn, keyLength) {
    let hashAlgId
    let asymAlgId
    if (keyLength === 256) {
      asymAlgId = 0x00020100
      hashAlgId = 0x00000001
    } else {
      asymAlgId = 0x00010000
      hashAlgId = 0x00000004
    }
    return this.ukeyMiddleware.handle('GenCertRequest', {
      devHandle: this.devHandle,
      dn,
      asymAlgId,
      ulBitsLen: 2048,
      hashAlgId,
      applicationName: '',
      containerName: ''
    }).then(res => {
      if (res.code === 0) {
        return Promise.resolve(res)
      } else {
        throw getErr('生成P10失败')
      }
    })
  }
  hasUkey () {
    return this.ukeyMiddleware.connect().then(res => {
      if (res.code !== 0) {
        throw getErr(false)
      } else {
        return this.ukeyMiddleware.handle('GetUkeyList', {})
      }
    }).then(res => {
      if (res.code === 0 && res.data.length > 0) {
        this.ukeyName = res.data[0]['uKey-alias']
        return this.disconnectUkey()
      } else {
        const that = this
        MessageBox.alert(
          `请插入Ukey。`,
          '提示',
          {
            type: 'warning',
            confirmButtonText: '好的',
            callback: () => {
              that.hasUkey().then(() => {
                that.self.ukeyLoginShow = true
              }).catch((err) => {
                console.log(err);
              });
            }
          }
        )
        throw getErr(false)
      }
    }).then(() => {
      return this.ukeyMiddleware.handle('ConnectUkey', { 'uKey-alias': this.ukeyName, applicationName: this.applicationName, containerName: this.containerName })
    }).then(res => {
      if (res.code !== 0) { throw getErr('Ukey链接失败') }
      this.devHandle = res.data.devHandle
      return this.ukeyMiddleware.handle('ExportMyUserCert', { devHandle: this.devHandle, CertType: 1 })
    }).then(res => {
      if (res.code !== 0) { throw getErr('读取证书信息失败') }
      let base64Cert = res.data.certContent
      this.base64Cert = base64Cert
      return this.ukeyMiddleware.handle('GetCertSpecificInfo', { base64Cert, infoType: PARAM.SGD_CERT_SERIAL })
    }).then(res => {
      if (res.code !== 0) { throw getErr('读取证书信息失败') }
      this.certSn = res.data.certInfo.toLowerCase()
      return Promise.resolve(this.certSn)
    })
  }
  disconnectUkey () {
    if (this.devHandle && this.devHandle !== '') {
      let devHandle = this.devHandle
      this.devHandle = ''
      return this.ukeyMiddleware.handle('DisconnectUkey', { devHandle })
    } else {
      return new Promise((resolve) => { resolve() })
    }
  }
  privOperlnWriteSeal () {
    return this.ukeyMiddleware.handle('PrivOperlnWriteSeal', { devHandle: this.devHandle }).then(res => {
      if (res.code !== 0) {
        throw getErr('印章格式化失败')
      }
      return res
    })

  }
}
